@import '@app/sass/variables.scss';
@import '@app/sass/mixins.scss';

.logo {
    height: 5.625rem;
    margin-top: 13em;
    text-align: center;

    &.logoIndex {
        margin-top: 7.1875rem;
    }
}

.displayMessage {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 0.9375rem;

    p {
        color: $white;
        font: $font20 $textFontFam;
        line-height: $defaultLineHeight;
        margin-bottom: 1em;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        text-align: center;
    }
}
