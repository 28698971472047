@import '../../sass/variables.scss';
@import '../../sass/mixins.scss';
@import '@flk/design-system/variables.scss';

.container {
    width: 100%;

    padding: 1rem;
    padding-top: 5.5rem;
    background: $backgroundGradient;
    color: $white;

    strong {
        font-weight: $boldFontWeight;
    }
}

.content {
    margin: 0 auto;
    width: min(100%, 42.5rem);
}

.greeting {
    font: $font28 $textFontBold;
    line-height: $font36;
    margin-bottom: 1.5rem;
}

.agentInfo {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}

.agent {
    font: $font18 $textFontBold;
    line-height: $font26;
    margin-bottom: 0.5rem;
}

.text {
    p {
        font: $font14 $textFontMedium;
        line-height: $font16;

        &:first-of-type {
            margin-bottom: 0.5rem;
        }

        &:last-of-type {
            margin-top: 1.5rem;
        }
    }

    .dottedUnderline {
        text-decoration: underline dotted;
        text-underline-offset: 4px
    }

    margin-bottom: 1.5rem;
}


.footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    .button {
        margin-top: 1rem;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }


    .disclaimer {
        font: $font12 $textFontMedium;
        line-height: $font14;
    }
}

.documentText {
    margin-bottom: 1rem;
}

.documentSection {
    display: flex;
    flex-direction: column;
    gap: $flk-space-4;
    background-color: $darkTint;
    border-radius: $flk-shape-2;
    padding: $flk-space-6;
    margin-bottom: 1.5rem;

    .document {
        display: flex;
        gap: $flk-space-4;
        align-items: center;
    }

    .documentIcon {
        height: 2rem;
        width: auto;
    }
    .download {
        margin-left: auto;
        background-color: transparent;
        .downloadIcon {
            color: $white;
        }
    }
}

.signingProcess {
    display: flex;
    flex-direction: column;
    gap: $flk-space-2;
    font-size: $font16;
    line-height: $font24;
    margin-bottom: 1.5rem;
    .step {
        display: flex;
        align-items: center;
        gap: $flk-space-4;
        .number {
            width: 2rem;
            height: 1.5rem;
            border-radius: $flk-shape-6;
            padding: $flk-space-1 $flk-space-3;
            background-color: $white;
            color: $black;
            border: 1px solid $white;
            font-weight: $boldFontWeight;
            font-size: $font14;
            line-height: $font16;
        }
    }
}

@include forTabletPortraitUp {
    .container {
        display: grid;
        place-items: center;
        padding: 1rem;
    }

    .greeting {
        font: $font32 $textFontBold;
        line-height: $font40;
        margin-bottom: 2rem;
    }

    .agent {
        font: $font20 $textFontBold;
        line-height: $font28;
        margin-bottom: 0.5rem;
    }

    .text {
        p {
            font: $font16 $textFontFam;
            line-height: $font24;

            &:first-of-type {
                margin-bottom: 0.75rem;
            }

            &:last-of-type {
                margin-top: 2rem;
            }
        }

        margin-bottom: 2rem;
    }

    .footer {
        h1 {
            font: $font20 $textFontBold;
            line-height: $font28;
        }

        .disclaimer {
            font: $font14 $textFontMedium;
            line-height: $font16;
        }
    }

}

.loadingContainer {
    min-height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    background: $backgroundGradient;

    .spinner {
        border-top-color: $white;
        border-right-color: $white;
        border-bottom-color: $white;
    }
}

.getStartedSpinner {
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    border: 2px solid transparent;
    border-top: 2px solid $primaryBlue;
    border-right: 2px solid $primaryBlue;
    border-bottom: 2px solid $primaryBlue;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}