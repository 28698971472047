@import "../../sass/variables.scss";
@import "../../sass/mixins";

.sign {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  outline: none;
}

.signModal,
.sign {
  .signHeader {
    padding: 1.5rem;
    .tenant {
      width: fit-content;
    }
    .title {
      font: $font18 $textFontBold;
      margin: 0.5rem;
      color: $black;
    }
  }

  .signFooter {
    background-color: $white;
    position: sticky;
    .buttonGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4rem;
      padding: 1rem 1.5rem;
      background: $darkVibrantPurple;
      .cancelButton {
        background: $darkVibrantPurple;
        border: 1px solid $white;
      }
    }
  }
}

.witnessForm {
    position: absolute;
    margin-top: 5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    
    .formElement {
        margin-top: 1rem;
        width: 100%;

        input {
          height: 3rem;
        }
    }
}

.witnessSignature {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $lightestBlue;
  
  .clientSignature {
    background-color: $lighterBlue;
    height: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    img {
      margin-top: 4rem;
      height: 8rem;
    }
  }

  .signaturePadBlock {
    background-color: $lightestBlue;
    position: relative;
    height: 100%;
    &.signatureBackground {
      .signCanvas {
        background-image: url("../../../public/images/signature_new.svg");
        background-size: 280px;
        background-repeat:no-repeat;
        background-position:center;
        opacity: 1;
      }
    }
    .buttonErase {
      // Icon have some white space in bottom
      // Therefore reduced the bottom padding
      padding: 1rem 1rem 0.5rem 1rem;
      background: transparent;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .buttonEmail {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    button {
      display: flex;
      gap: 0.5rem;
      padding: 0 0 0 1rem;
      font: $font16 $textFontFam;
      color: $primaryBlue;
      line-height: $defaultLineHeight;
      cursor: pointer;
      align-items: center;
      svg {
        color: $lighterBlue;
        height: 24px;
        width: 24px;
        rect, path {
          stroke: $primaryBlue;
        }
      } 
    }
  }


  .label {
    text-align: center;
    margin-top: 1.5rem;
  }

  .signedText {
    color: $darkMidGray;
    text-align: center;
    margin-bottom: 2rem;
  }
}
