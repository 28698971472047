@import "../../sass/variables.scss";
@import "../../sass/mixins";

.noteBlock {
  font-size: $font16;
  font-weight: 400;
  line-height: 1;
  color: $black;
  background: $quaternaryBlue;
  padding: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font: $font20 $textFontBold;
      line-height: 1;
      margin: 0;
    }
    .infoButton {
      width: 24px;
      height: 24px;
      background: $primaryBlue;
      border-radius: 16px;
      .icon {
          color: $white;
          width: 12px;
          height: 12px;
      }
  }
    .titleGroup {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .expandButton {
      height: 24px;
      width: 24px;
      svg {
        color: $white;
      }
      &.open {
        transform: rotate(180deg);
        transition: 1s ease;
      }
      transition: 1s ease;
    }
  }
  .noticeBlock {
    margin: 1rem 0 0.5rem 0;
    font-size: $font16;
  }
}
