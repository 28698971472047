@import "variables";
@import "mixins";
.checkbox-details {
    --size: 28px;
    --border-colour: #ffffff;
    --border-width: 2px;
    --border-radius: 15%;
    --background-colour: rgba(255, 255, 255, 0.2);
    --inner-colour: #9d9d9b;
    --buffer: 20px;
    --margin-right: 0;
    --font-size: 16px;
    --check-size: 16px;

    &.checkbox-details--small {
      --size: 20px;
      --font-size: $font16;
      --border-width: 1px;
      --buffer: 16px;

      .checkbox {
        .label-text-checkbox {
          font: $font16/1.5 $textFontMedium;
        }

        .checkmark {
          margin-top: 1px;
        }
      }
    }

    &.checkbox-details--blue {
      --border-colour: #{$darkPrimaryBlue};
      --inner-colour: #{$darkPrimaryBlue};
      --checked-background-colour: #{$primaryBlue};
      --background-colour: #{$secondaryBlue};
    }
    &.checkbox-details--disabled {
      --border-colour: #{$darkMidGray};
      --inner-colour: #{$midGray};
      --checked-background-colour: #{$midGray};
      --background-colour: #{$midGray};
    }

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include makeCheckbox(var(--size),
                            var(--border-colour),
                            var(--border-width),
                            var(--border-radius),
                            var(--background-colour),
                            var(--inner-colour),
                            var(--buffer),
                            var(--margin-right),
                            var(--font-size),
                            var(--check-size),
                            "icons_check_white.svg",
                            var(--checked-background-colour)
                            );

      .label-text-checkbox {
        font: $font24 $textFontFam;
        overflow-wrap: anywhere;
      }

      .checkmark {
        margin-top: 8px;
      }
    }

    &.margin-bottom {
      margin-bottom: 15px;
      .checkbox {
        .checkmark {
          margin-top: 0;
        }
      }
    }
    &.with-border {
      padding-top: 15px;
      border-top: 1px solid white;
    }
}
