@use 'sass:math';

// Colors
// Black, Gray, White
$white: #ffffff;
$gray100: #fbfbfb;
$gray200: #f6f7fb;
$gray300: #eef0f3;
$gray400: #e8e9eb;
$gray500: #caced9;
$gray600: #9a9eab;
$gray650: #949DB0;
$gray700: #696c77;
$gray800: #455154;
$black: #000000;
//Blue
$blue50: #f9fbff;
$blue100: #ecf2ff;
$blue200: #d0deff;
$blue300: #a0beff;
$blue350: #568FF0;
$blue400: #427cff;
$blue450: #2A68FF;
$blue500: #0b56ff;
$blue600: #0c3dad;
//Red
$red25: #FFF9FA;
$red50: #fdd5dc;
$red75: #FFAFBD;
$red100: #fe5e7d;
$red150: #fda083;
$red200: #e55656;
$red300: #ce2445;
$red400: #d32e2e;
$red500: #CA0241;

// Green
$green50: #d8f2ce;
$green100: #b2dea1;
$green200: #78d953;
$green300: #299400;
$green400: #2d5c1a;
$green500: #34B004;
//Purple
$purple100: #c7a5ff;
$purple200: #56336A;
$purple300: #654ACF;
$purple400: #4B31B9;
//Orange
$orange700: #9D6200;
$orange500: #ff8b66;
$orange400: #e28d00;
$orange300: #ffbb49;
$orange200: #ffc86d;
$orange100: #fbd79b;
$orange50: #fce2b9;
$orange25: #FFF1DB;
//Yellow
$yellow700: #FBA414;
$yellow600: #ca9d00;
$yellow500: #f0c014;
$yellow400: #ffdb58;
$yellow300: #FDE7A7;
$yellow50: #fff9e1;

$mint400: #b2e3da;
$mint500: #33b69f;

$pink400: #ff67a7;
$pink500: #ff4b97;
$pink600: #e92c7b;

$aqua400: #7cdaee;
$aqua500: #0598b8;



// Font size
$fontsize: 16px;
$font9: #{math.div(9px, $fontsize)}rem;
$font10: #{math.div(10px, $fontsize)}rem;
$font11: #{math.div(11px, $fontsize)}rem;
$font12: #{math.div(12px, $fontsize)}rem;
$font13: #{math.div(13px, $fontsize)}rem;
$font14: #{math.div(14px, $fontsize)}rem;
$font15: #{math.div(15px, $fontsize)}rem;
$font16: #{math.div(16px, $fontsize)}rem;
$font17: #{math.div(17px, $fontsize)}rem;
$font18: #{math.div(18px, $fontsize)}rem;
$font20: #{math.div(20px, $fontsize)}rem;
$font21: #{math.div(21px, $fontsize)}rem;
$font22: #{math.div(22px, $fontsize)}rem;
$font24: #{math.div(24px, $fontsize)}rem;
$font25: #{math.div(25px, $fontsize)}rem;
$font26: #{math.div(26px, $fontsize)}rem;
$font27: #{math.div(27px, $fontsize)}rem;
$font28: #{math.div(28px, $fontsize)}rem;
$font32: #{math.div(32px, $fontsize)}rem;
$font38: #{math.div(38px, $fontsize)}rem;
$font40: #{math.div(40px, $fontsize)}rem;
$font44: #{math.div(44px, $fontsize)}rem;

//Fonts
$textFontFam: Texta, Arial, sans-serif;

// Font weights
$lightFontWeight: 300;
$normalFontWeight: 400;
$mediumFontWeight: 500;
$boldFontWeight: 700;

$primaryGradient: linear-gradient(225deg, $purple300 0%, $blue400  100%);
$secondaryGradient: linear-gradient(180deg, $blue400 0%, $purple300 100%);

// Spacing
$flk-space-20: 5rem;
$flk-space-16: 4rem;
$flk-space-10: 2.5rem;
$flk-space-8: 2rem;
$flk-space-6: 1.5rem;
$flk-space-4: 1rem;
$flk-space-3: 0.75rem;
$flk-space-2: 0.5rem;
$flk-space-1: 0.25rem;
$flk-space-0: 0rem;

// Shapes
$flk-shape-round: 100%;
$flk-shape-6: 24px;
$flk-shape-2: 8px;
$flk-shape-1: 4px;
$flk-shape-half: 2px;
$flk-shape-zero: 0px;

// Depth
$flk-depth-6 : 0px 6px 24px;
$flk-depth-5 : 0px 5px 20px;
$flk-depth-4 : 0px 4px 16px;
$flk-depth-3 : 0px 3px 12px;
$flk-depth-2 : 0px 2px 8px;
$flk-depth-1 : 0px 1px 4px;

:export {
    blue50: $blue50;
    blue100: $blue100;
    blue200: $blue200;
    blue300: $blue300;
    blue350: $blue350;
    blue400: $blue400;
    blue500: $blue500;
    blue600: $blue600;
}
