@import "../../sass/variables.scss";

.container {
    background-color: $lightTint;
    color: $white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    overflow: hidden;

    .container {
        overflow: hidden;

        &.expanded {
            max-height: unset;
        }
    }

    .title {
        font: $textFontBold;
        font-size: $font20;
        line-height: 1.25;
    }

    .body {
        font-family: $textFontLight;
        font-size: $font16;
        line-height: 1.5;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .button {
        align-self: flex-start;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;
        margin: -0.5rem;

        &.icon {
            width: 1.25rem;
            transition: transform 150ms ease-in-out;
        }

        &.expanded {
            .icon {
                transform: rotate(180deg)
            }
        }
    }
}