@import "@flk/design-system/variables.scss";

.container {
    display: flex;
    gap: $flk-space-10;
    margin: 0 auto;
    padding: 0.5rem 0 0 0;
}

.step {
    position: relative;
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $flk-space-2;

    width: $flk-space-20;

    .icon,
    .circle {
        box-sizing: border-box;
        height: $flk-space-4;
        width: $flk-space-4;
    }

    .circle {
        border-radius: $flk-shape-round;
        border: 2px solid $gray650;
    }

    .icon {
        padding: 0.125rem;
        color: white;
        border-radius: 100%;
        background-color: $blue500;
    }

    .line {
        position: absolute;
        width: 6.5rem;
        left: calc(50% + $flk-space-2);
        top: $flk-space-2;
        height: 2px;
        background-color: $gray650;
    }

    .title {
        font-size: $font14;
        color: $gray650;
    }

    &.current,
    &.complete {
        .circle {
            border-color: $blue500;
        }

        .title {
            color: $blue500;
        }
    }

    &.complete {
        .line {
            background-color: $blue500;
        }
    }
}