@import "@flk/design-system/variables.scss";

.desktopSigningOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#01031F, 0.48);
}

.desktopSigningModal {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .desktopSigningContent {
        width: 31rem;
        padding: 1.5rem;
        max-height: 100%;
        overflow: auto;
    }

    .body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }

    .closeButton {
        background-color: transparent;
        color: $gray600;

        .closeIcon {
            height: 1.75rem;
            width: 1.75rem;
        }
    }

    h1 {
        font-weight: $boldFontWeight;
        font-size: $font24;
        line-height: $font32;
        letter-spacing: -1%;
    }

    h2 {
        font-weight: 700;
        font-size: $font16;
        line-height: $font24;
    }

    .witnessStepButtons {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
    }

    .qrCodeContainer {
        padding: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;
        background-color: $gray200;
    }

    .qrCode {
        max-width: 8.5rem;
        height: auto;
        aspect-ratio: 1/1;
        padding: 1rem;
        box-sizing: border-box;
        background-color: $white;

        box-shadow: 0px 1px 4px 0px #272E4214;
        box-shadow: 0px 1px 4px 0px #272E421F;
    }

    .qrCodeSectionHeader {
        line-height: 1.5;
        font-weight: $boldFontWeight;
    }

    .qrCodeInstructions {
        color: $gray700;
        font-weight: $mediumFontWeight;
        padding-left: 1rem;
        font-size: $font14;
        line-height: $font16;
    }

    .smsForm {
        display: grid;
        // l - label
        // m - mobileInput
        // s - sendSmsButton
        grid-template-areas:
            "l l"
            "m s";
        grid-template-columns: 1fr auto;
        row-gap: 0.5rem;
        column-gap: 1rem;


        .label {
            grid-area: l;

            font-weight: $boldFontWeight;
            font-size: $font16;
            line-height: $font24;
        }

        .mobileInput {
            grid-area: m;
        }

        .sendSmsButton {
            grid-area: s;
            place-self: flex-start;
        }
    }

    .sendSmsButton,
    .confirmSignatureButton {
        min-width: 0;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .useCurrentSignatureButton {
        margin-top: 0.5rem;
        margin-left: auto;
    }


    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
}

body.scrollLock {
    overflow: hidden;
}