@import '@app/sass/variables.scss';
@import '@app/sass/mixins.scss';
@import '@flk/design-system/variables.scss';

.rootContainer {
    &:not(.mobile) {
        height: 100%;
        overflow: auto;
    }

    &.mobile {
        width: 100%;
    }

    position: relative;
}

.header {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    height: 4.5rem;
    padding: 1rem;
    background: linear-gradient(92deg, #2a68ff 0%, #654acf 100%);

    .title {
        font: $font18 $textFontBold;
        line-height: $font26;
        color: $white;
        margin: 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .back,
    .download {
        background-color: transparent;
        border-radius: 0;
        color: $white;
    }
}

.subHeader {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    height: 4.5rem;
    padding: 0.5rem;
    background: $white;

    &.desktop {
        justify-content: center;
        position: relative;

        .toggle {
            width: auto;

            button {
                width: auto;
                color: $black;
            }
        }

        .downloadButton {
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 1rem;
            background: $white;
            border-color: #2a68ff;
        }
    }
}

.content {
    padding: 1rem 2rem;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem 2rem 1rem;

    .confirm {
        width: 100%;

        &.desktop {
            max-width: 21rem;
        }
    }
}

.container {
    background: #f6f7fb;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.scrollToBottom {
    display: grid;
    grid-template-columns: 1fr auto;
    place-items: center;
    gap: 1rem;
    position: sticky;
    bottom: 2rem;
    margin: 0 1rem;
    padding: 1rem;
    border-radius: 4px;
    background: #01031feb;
    color: $white;
    font-size: $font14;
    line-height: $font16;

    .close {
        background-color: transparent;

        .closeIcon {
            color: $white;
        }
    }

    &.desktop {
        max-width: 35rem;
        margin: 0 auto;
    }
}

.bounce {
    animation: bounce 0.75s ease;
    animation-iteration-count: 2;

    @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-30px);
        }

        60% {
            transform: translateY(-15px);
        }
    }
}

@include forTabletLandscapeUp {
    .content {
        // Effectively declaring the width of the contents, but using padding to avoid moving the scroll bar
        padding: 1rem calc((100% - clamp(30rem, 75%, 50rem))*0.5);
    }
}

.largeFilePlaceholder {
    /* Add extra padding at bottom to counter white space around document icon (due to shadow) */
    padding: $flk-space-2 $flk-space-2 $flk-space-4 $flk-space-2;
    height: 100%;
    border-radius: $flk-shape-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--textFieldGray);
    background-color: var(--white);

    .iconContainer {
        position: relative;
    }

    .fileSize {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--darkMidGray);
        text-align: center;
    }

    .icon {
        width: 120px;
        height: auto;
    }

    .text {
        max-width: 30ch;
        text-align: center;
        line-height: 1.25;
        margin-bottom: $flk-space-4;
    }

}

.progressBar {
    max-width: 730px;
    margin: 0 auto;

    .progressLine {
        margin: 0;
        padding: $flk-space-1;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: black;
        height: 1rem;
        border-radius: $flk-shape-1;
        background-color: $blue300;
        margin-top: $flk-space-1;
    }
}

.loadError {
    display: flex;
    gap: $flk-space-2;
    align-items: center;
    justify-content: center;
    margin-top: $flk-space-10;

    .errorIcon {
        color: $white;
        border-radius: $flk-shape-round;
        background-color: $midRed;
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
