@import "@app/sass/variables";

.clientsCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .clientContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .collapsedContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .client {
    font-family: $textFontMedium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }

  .singleClient {
    font-family: $textFontMedium;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .controlIcon {
    transition: transform 150ms ease-in-out;
    margin-left: auto;
  }

  .controlIconExpanded {
    transform: rotate(180deg);
  }

  // Give tags a standard width based on content because different numbers have slightly different width
  .clientTag {
    min-width: 6ch;
    box-sizing: content-box;
  }

  .clientDetails {
    display: flex;
    flex-direction: column;
    max-width: 17ch;
    overflow: hidden;

    span {
      text-overflow: ellipsis; 
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .subDetails {
    color: $darkMidGray;
  }
 
  .clientName {
    display: unset;
    color: $black;
  }

  .otherClients {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }
}

.cardControl {
  align-items: start;
}

.tootTipText {
    background-color: $black;
    color: $white;
    border-radius: 0.5rem;
    padding: 1rem;
}

.plusIcon {
  color: $darkMidGray;
}

.header {
    display: flex;
    align-self: stretch;
    align-items: center;
}

