@import '../../sass/variables.scss';
@import '../../sass/mixins';
@import "@flk/design-system/variables.scss";

.signDocument {
    display: flex;
    flex-direction: column;
    outline: none;

    .header {
        align-items: center;
        background: $backgroundGradientVertical;
        display: flex;
        height: 4.5rem;
        column-gap: 0.5rem;
        padding: 1rem;

        .backButton {
            background-color: unset;
            color: $white;
            width: 2.25rem;
        }

        h1 {
            color: $white;
            font: $font18 $textFontBold;
            line-height: $font26;
            margin-right: auto;
        }

        .infoButton {
            background-color: unset;
            width: 2.5rem;

            .openIcon {
                background-color: white;
                border-radius: 100%;
                color: $darkVibrantPurple;
                padding: 0.25rem;
            }
        }
    }

    .body {
        padding: 1.5rem 1rem 2rem;
        display: flex;
        flex-direction: column;

        .signerSection {
            align-items: center;
            column-gap: 1rem;
            display: flex;
            height: 1.5rem;

            .tenant {
                height: 100%;
                width: fit-content;
                flex-shrink: 0;
            }

            .title {
                color: $black;
                font: $font18 $textFontBold;
                margin: 0.5rem;
            }
        }

        .buttonEmail {
            color: $primaryBlue;
            font: $font14 $textFontFam;
            line-height: $font16;
            margin-top: 1rem;
            padding: unset;
            text-align: left;
            text-decoration: underline;

            &.strong {
                font-weight: 800;
            }
        }

        .signaturePadBlock {
            aspect-ratio: 1/1;
            background-color: $lightestBlue;
            border-radius: 4px;
            border: 1px solid $secondaryBlue;
            margin-top: 2rem;
            min-height: 0;
            position: relative;

            .signCanvas {
                height: auto;
                max-height: 100%;
                max-width: 100%;
                width: auto;
            }

            &.signatureBackground {
                .signCanvas {
                    background-image: url('../../../public/images/signature_new.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 280px;
                    opacity: 1;
                }
            }

            &.initialBackground {
                .signCanvas {
                    background-image: url('../../../public/images/signature-small.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 200px;
                    opacity: 1;
                }
            }

            .buttonErase {
                // Icon have some white space in bottom
                // Therefore reduced the bottom padding
                background: transparent;
                bottom: 0;
                padding: 1rem 1rem 0.5rem 1rem;
                position: absolute;
                right: 0;
            }
        }

        .buttonDone {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $flk-space-3;
        }
    }
}

@media screen and (max-width: 1024px) {
    #github {
        display: none;
    }
}
