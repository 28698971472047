@import "@app/sass/variables.scss";
@import '@flk/design-system/variables.scss';

$formWidth: min(100%, 50rem);

.clientCustomPlaceholders {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    background-color: $lightGrey;
    position: relative;
    b {
      font-family: $textFontBold;
    }
    &.loading {
        opacity: 0.5;
    }
    .firstInstruction {
      margin-bottom: 1rem;
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-self: center;

        width: $formWidth;
        padding: 1rem 1rem 7rem 1rem;
    }
    .section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .footer {
        border-radius: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;

        display: flex;
        justify-content: center;

        .footerSection {
            padding: 1rem;
            width: $formWidth;

            display: flex;
            justify-content: flex-end;
            align-items: center;

            .buildingText {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            .submitButton {
                display: flex;
                gap: 0.5rem;
            }
        }
    }
    .spinner {
        display: inline-block;
        vertical-align: middle;
        align-self: center;
        border: 2px solid transparent;
        border-top: 2px solid $primaryBlue;
        border-right: 2px solid $primaryBlue;
        border-bottom: 2px solid $primaryBlue;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
    }

    .documentDescription {
        background-color: $gray200;
        border-radius: $flk-shape-2;
        padding: $flk-space-6;
        color: $darkGray;
        font-weight: $mediumFontWeight;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
}
