@import "../variables.scss";
@import "../../sass//mixins.scss";


$borderRadius: 8px;

.container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;


    .inputContainer {
        background-color: $textFieldBlue;
        border-radius: $borderRadius;

        display: flex;
        flex-direction: row;
        align-items: center;

        &:focus-within {
            outline: 1px solid $primaryBlue;
        }
    }

    .endButton {
        align-self: stretch;
        padding: 0.5rem;

        display: grid;
        place-items: center;

        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;

        background: none;

        // Override leaky styles
        width: auto;
        height: auto;
    }

    .clearButton {
        .clearIcon {
            color: $primaryBlue;
            background-color: $lighterBlue;
            border-radius: 100%;
        }

        &:hover,
        &:focus {
            .clearIcon {
                outline: 1px solid $primaryBlue;
            }
        }

        &:active {
            .clearIcon {
                outline: 1px solid $darkPrimaryBlue;
                background-color: $primaryBlue;
                color: $darkPrimaryBlue;
            }
        }
    }

    .input {
        flex: 1;

        background: none;
        font-size: $font18;
        line-height: $font25;
        padding: 0.5rem;
        caret-color: $primaryBlue;
        box-sizing: border-box;
        border: none;
        color: $colorText;
        letter-spacing: -0.6px;
        min-width: 10ch;

        @include placeholder {
            color: $textFieldGray;
            font-style: italic;
        }

        // Hide autofill background color
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 1000px $textFieldBlue inset !important;
            -webkit-text-fill-color: $colorText !important;
        }

    }

    &.disabled {
        .inputContainer {
            background-color: $disabledInputBackground;
        }

        .input {
            @include placeholder {
                visibility: hidden;
            }
        }

        .clearButton {
            background-color: $disabledInputBackground;

            .clearIcon {
                background-color: $midGray;
                color: $textFieldGray;
            }
        }

        .endButton {
            opacity: 0.5;
        }
    }

    &.withError {
        color: $error;

        .label {
            color: $error;
        }

        .inputContainer {
            outline-color: $error;
            background-color: $errorInputBackground;
        }

        .error {
            font-size: $font14;
        }

        .clearButton {
            .clearIcon {
                color: $midRed;
                background-color: rgba($midRed, 0.2);
            }

            &:hover,
            &:focus {
                .clearIcon {
                    outline: 1px solid $midRed;
                }
            }

            &:active {
                .clearIcon {
                    outline: 1px solid $darkRed;
                    background-color: $midRed;
                    color: $darkRed;
                }
            }
        }
    }
}

.label {
    font-size: $font14;
    font-family: $textFontLight;
    color: $textFieldGray;
}
