@import "@flk/design-system/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.checkbox {
    position: relative;
}

.confirmButton {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $flk-space-3;
}