@import "@flk/design-system/variables.scss";

.signaturePanelHeading {
    display: grid;
    grid-template-columns: 1fr 1fr;

    h2 {
        font-weight: 700;
        font-size: $font16;
        line-height: $font24;
    }
}

.signaturePanelContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .signaturePanel {
        border-radius: 8px;
        height: 9rem;
        display: flex;

        background-color: $gray300;
        border: 1px solid $gray500;


        &.pending {
            background-color: $blue100;
            border-color: $blue200;

            .signatureContainer {
                border-color: $blue200;
            }
        }

        .loadingText {
            font-weight: $boldFontWeight;
            font-size: $font14;
            line-height: $font16;
        }

        .signature {
            max-height: 100%;
            max-width: 100%;
        }

        .signatureContainer {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
            padding: 1.5rem;

            &:not(:last-of-type) {
                border-right: 1px solid $gray500;
            }
        }
    }
}
