@import "@app/sass/variables";
@import "@app/sass/mixins";

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .document {
        gap: 1rem;
        display: flex;
        align-items: center;

        .startIcon {
            width: 2.5rem;
        }

        .chevronRightIcon,
        .checkIcon {
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 100%;
        }

        .chevronRightIcon {
            color: $darkPrimaryBlue;
        }

        .documentContent {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            transition: background-color 150ms ease-in-out color 150ms ease-in-out;

            .documentName {
                font: $font14/$font16 $textFontMedium;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .endText {
            border-radius: 8px;
            padding: 0.5rem;
            color: $white;
            background-color: $primaryBlue;
        }

        &.selected {
            outline: 1px solid $primaryBlue;
            background-color: $lighterBlue
        }

        &.disabled {
            opacity: 0.7;
        }

        &.reviewed {
            background-color: $lightestGreen;

            .checkIcon {
                color: $darkMidGreen;
                background-color: $lightGreen;
                border-radius: 100%;
            }

            .endText {
                display: none;
            }

            &.selected {
                border: 2px solid $darkMidGreen;
                margin: -2px;

                .documentName {
                    color: $darkestGreen;
                    font-family: $textFontBold;
                }
            }
        }
    }
}
