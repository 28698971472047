@import '../../sass/variables.scss';

.container {
    padding: 1.5rem 1rem;
    background: $backgroundGradient;
    color: $white;
    width: 100%;
    height: var(--viewport-height);
    overflow: auto;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.header {
    height: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h1 {
        font: $font18 $textFontBold;
        line-height: $font26;
    }

    .backButton {
        color: $white;
        background-color: unset;
    }
}

.separator {
    height: 1px;
    background-color: $white;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
        font: $font16 $textFontBold;
        line-height: $font24;
    }
}

.helpText {
    font: $font12 $textFontMedium;
    line-height: $font14;
}
