@import "../../sass/mixins";

.pdf-viewer__pages {
    width: 100%;
}

.pdf-viewer__page {
    margin: 16px 0;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

.pdf-viewer__page .react-pdf__Page canvas {
    max-width: 100%;
    height: auto !important;
}

.pdf-viewer__page--rendering .react-pdf__Page {
    visibility: hidden;
}

@include forTabletLandscapeUp {
    .pdf-viewer__page {
        box-shadow: 0px 11.5px 16.5px rgba($black, 0.25);
    }
}