@import "variables";

.solid {
  outline: solid;
  outline-width: 1px;
}

.tag {
  color: $black;
  padding: 0.25rem 0.75rem;
  font: $font16 $textFontFam;
  line-height: 1.5;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;

  &.tag--green {
    background-color: $lightGreen;
  }

  &.tag--green-solid {
    @extend .tag--green;
    @extend .solid;
    outline-color: $darkestGreen;
  }

  &.tag--orange {
    background-color: $midOrange;
  }

  &.tag--orange-solid {
    @extend .tag--orange;
    @extend .solid;
    outline-color: $darkestOrange;
  }

  &.tag--yellow {
    background-color: $yellow;
  }

  &.tag--yellow-solid {
    @extend .tag--yellow;
    @extend .solid;
    outline-color: $darketsYellow;
  }

  &.tag--blue {
    background-color: $secondaryBlue;
  }

  &.tag--blue-solid {
    @extend .tag--blue;
    @extend .solid;
    outline-color: $primaryBlue;
  }

  &.tag--purple {
    background-color: $midVibrantPurple;
  }

  &.tag--purple-solid {
    @extend .tag--purple;
    @extend .solid;
    outline-color: $darkVibrantPurple;
  }

  &.tag--pink {
    background-color: $midPink;
  }

  &.tag--pink-solid {
    @extend .tag--pink;
    @extend .solid;
    outline-color: $darkestPink;
  }

  &.tag--mint {
    background-color: $mint;
  }

  &.tag--mint-solid {
    @extend .tag--mint;
    @extend .solid;
    outline-color: $darkestMint;
  }

  &.tag--aqua {
    background-color: $aqua;
  }

  &.tag--aqua-solid {
    @extend .tag--aqua;
    @extend .solid;
    outline-color: $darkestAqua;
  }

  &.tag--red {
    background-color: $tagRed;
  }

  &.tag--red-solid {
    @extend .tag--red;
    @extend .solid;
    outline-color: $midRed;
  }

  &.tag--midGray {
    background-color: $lightestGray;
  }

  &.tag--midGray-solid {
    @extend .tag--midGray;
    @extend .solid;
    outline-color: $midGray;
  }

  &.tag--lightestBlue {
    background-color: $lightestBlue;
  }
}
