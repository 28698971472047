.mobileContainer {
    width: 100%;
}

.desktopContainer {
    display: grid;
    grid-template-columns: 23.5rem 1fr;
    width: 100%;
    height: 100vh;

    // We only show the splash screen from the CustomDocumentInfo page when complete
    &.complete {
        grid-template-columns: 1fr;
    }
}

