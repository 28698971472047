@import "../../sass/variables.scss";
@import "../../sass/mixins";

.witnessInstruction {
    display: flex;
    flex-direction: column;
    background-color: $white;

    .closeButton {
        margin-right: 1.5rem;
        margin-top: 1.5rem;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 3.5rem;
    margin-right: 1.5rem;

    .instructionNote {
        margin-left: 1.5rem;
        margin-bottom: 1rem;
    }

    .button {
        height: 3rem;
        margin-left: 1.5rem;
    }
}

.desktopContent {
    margin-top: 2rem;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 3rem;
}

.noWitnessHeader {
    align-self: center;
}

.noWitnessContent {
    margin-left: 1.5rem;
}

.noWitnessText {
    text-align: center;
}


.closeButton {
    align-self: end;
}

.desktopNoWitnessModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(214, 219, 230, 0.75);
}

.desktopNoWitnessModal {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20rem;
    height: 10rem;
    border-radius: 0.5rem;
    margin-top: 200px;
}

.desktopModalContent {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.desktopInstructionNote {
    background-color: $lighterBlue;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
    padding: 0;

}

.desktopNoWitnessModalBody {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.5rem;
    padding-left: 1rem;     
}

.desktopFoundWitnessButton {
    width: fit-content;
    align-self: center;
    
    margin-bottom: 1rem;

}
