@import "../variables.scss";

.formInputCheckbox {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: $lightestBlue;
    border: 1px solid $lighterBlue;
    border-radius: 8px;
    padding: 1rem;
    input {
        display: none;
    }
    &.active {
        background: $quaternaryBlue;
        border: 1px solid $primaryBlue;
    }
    &.disabled {
        pointer-events: none;
        cursor:not-allowed
    }

    .checkIcon {
        height: 24px;
        min-width: 24px;
        border: 1px solid $secondaryBlue;
        background: $quaternaryBlue;
        border-radius: 4px;
        &.active {
            background: $primaryBlue;
            border: 1px solid $primaryBlue;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                color: $white;
                height: 18px;
                width: 18px;
            }
        }
    }
}