@import "variables";

.sender-card {
  padding: 1rem;
  display: grid;
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: auto 1fr auto;
  // l - logo
  // t - tag
  // n - agencyName
  // a - agent
  // i - infoButton
  grid-template-areas: "l t i"
                       "l n i"
                       "l a i";
  place-items: center flex-start;

  .sender-card__logo {
    grid-area: l;
    place-self: flex-start center;
    width: 4.5rem;
  }
  .sender-card__agency {
    grid-area: n;
    font-size: $font18;
    line-height: 1;
  }

  .sender-card__agent {
    grid-area: a;
    font: $font16 $textFontFam;
    color: $darkMidGray;
    line-height: 1;
  }

  .sender-card__info-button {
    grid-area: i;
    place-self: flex-start center;
    background: none;
  }
}
