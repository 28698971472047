@import '../../sass/variables.scss';

.openButton {
    .openIcon {
        background-color: $white;
        border-radius: 100%;
        padding: 0.25rem;
    }
}

.overlay {
    position: fixed;
    background-color: $modalOverlay;
    backdrop-filter: blur(4px);
    inset: 0px;
    padding: 1rem;
    display: grid;
    place-items: center;
    z-index: 1;
}

.modal {
    padding: 1.5rem;
    margin: 1rem;
    border-radius: 8px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: min(100%, 28rem);
}

.agentDetails {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.agentImage {
    width: 2.5rem;
    aspect-ratio: 1;
    border-radius: 100%;
    object-fit: cover;
}

.agentName {
    font: $font18 $textFontBold;
    line-height: $font26;
}

.agencyName,
.agencyAddress {
    font: $font14 $textFontMedium;
    line-height: $font16;
}

.agencyAddress {
    color: $textFieldGray;
}

.contactDetails {
    display: grid;
    // l - label
    // n - number
    // c - copy
    // p - phone
    grid-template-areas:
        'l c p'
        'n c p';
    grid-template-columns: 1fr minmax(0, auto) minmax(0, auto);
    column-gap: 1rem;

    .label {
        grid-area: l;
        font: $font12 $textFontMedium;
        line-height: $font14;
        align-self: flex-end;
    }

    .number {
        grid-area: n;
        font: $font16 $textFontMedium;
        line-height: $font24;
        align-self: flex-start;
    }

    .copy {
        grid-area: c;
    }

    .phone {
        grid-area: p;

    }

    .copy, 
    .phone {
        border-radius: 8px;
        border: 2px solid $primaryBlue;
        padding: 0.75rem;
    }

    .copyIcon,
    .phoneIcon {
        box-sizing: border-box;
        color: $primaryBlue;
        width: 1rem;
        height: 1rem;
    }

    .copy {
        width: unset;
        height: unset;
        background-color: unset;
        .copyIcon {
            border: unset;
        }
    }
}

.closeButton {}