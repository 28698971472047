@import "../../sass/variables";

.toggleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.25rem;
    width: 100%;
    background: #F9FBFF;
    border-radius: 8px;
    .button {
        background: #F9FBFF;
        border-color: #D0DEFF;
        width: 50%;
        &.selected {
            border-color: #2A68FF;
            background: $white;
        }
    }
}